import { Injectable } from '@angular/core';

import { Tag } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class TagsService extends GenericObjectsService<Tag> {
  protected baseUrl = '/tags';

  createCustomerLinks(id: string, customerIds: string[]): Promise<{ success: boolean }> {
    return this.apiService.postPromise(`${this.baseUrl}/${id}/customers/create-links`, customerIds);
  }

  deleteCustomerLinks(id: string, customerIds: string[]): Promise<{ success: boolean }> {
    return this.apiService.postPromise(`${this.baseUrl}/${id}/customers/delete-links`, customerIds);
  }

  createConversationLinks(id: string, customerIds: string[]): Promise<{ success: boolean }> {
    return this.apiService.postPromise(`${this.baseUrl}/${id}/conversations/create-links`, customerIds);
  }

  deleteConversationLinks(id: string, customerIds: string[]): Promise<{ success: boolean }> {
    return this.apiService.postPromise(`${this.baseUrl}/${id}/conversations/delete-links`, customerIds);
  }

  createKnowledgeBaseArticleLinks(id: string, knowledgeBaseArticleIds: string[]): Promise<{ success: boolean }> {
    return this.apiService.postPromise(
      `${this.baseUrl}/${id}/knowledge-base-articles/create-links`,
      knowledgeBaseArticleIds,
    );
  }

  deleteKnowledgeBaseArticleLinks(id: string, knowledgeBaseArticleIds: string[]): Promise<{ success: boolean }> {
    return this.apiService.postPromise(
      `${this.baseUrl}/${id}/knowledge-base-articles/delete-links`,
      knowledgeBaseArticleIds,
    );
  }

  createKnowledgeBaseScrapeJobLinks(id: string, knowledgeBaseScrapeJobIds: string[]): Promise<{ success: boolean }> {
    return this.apiService.postPromise(
      `${this.baseUrl}/${id}/knowledge-base-scrape-jobs/create-links`,
      knowledgeBaseScrapeJobIds,
    );
  }

  deleteKnowledgeBaseScrapeJobLinks(id: string, knowledgeBaseScrapeJobIds: string[]): Promise<{ success: boolean }> {
    return this.apiService.postPromise(
      `${this.baseUrl}/${id}/knowledge-base-scrape-jobs/delete-links`,
      knowledgeBaseScrapeJobIds,
    );
  }
}
